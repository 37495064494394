<template>
	<div>
		<template v-if="isMobile">
			<v-row class="counter-row justify-space-between">
				<v-btn icon @click="decrement" class="circular-button mx-1" :disabled="!canDecrement">
					<v-icon>mdi-minus</v-icon>
				</v-btn>
				<span class="counter-value"
					:class="isModifier ? 'mx-6' : 'mx-12'">{{ currentQuantity }}</span>
				<v-btn icon @click="increment" class="circular-button mx-1" :disabled="!canIncrement">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-row>
			<v-row v-if="minQuantity || maxQuantity">
				<span class="text-caption text-grey-dark opacity-50 ml-1 mt-2">
					<span v-if="minQuantity && maxQuantity">{{$t('product.quantityBoth', {min : minQuantity, max : maxQuantity})}}&nbsp;</span>
					<span v-else-if="minQuantity">{{$t('product.quantityMin', {min : minQuantity})}}</span>
					<span v-else-if="maxQuantity">{{$t('product.quantityMax', {max : maxQuantity})}}</span>
				</span>
			</v-row>
		</template>
		<template v-else>
			<v-row :justify="align === 'left' ? 'start' : 'end'" class="counter-row">
				<v-btn icon @click="decrement" class="circular-button mx-1" :disabled="!canDecrement">
					<v-icon>mdi-minus</v-icon>
				</v-btn>
				<span class="counter-value"
					:class="isModifier ? 'mx-6' : 'mx-12'">{{ currentQuantity }}</span>
				<v-btn icon @click="increment" class="circular-button mx-1" :disabled="!canIncrement">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-row>
			<v-row class="mt-4" v-if="minQuantity || maxQuantity">
				<span class="text-caption text-grey-dark opacity-50" :class="{'ml-auto' : align === 'right', 'ml-2' : align === 'left'}">
					<span v-if="minQuantity && maxQuantity">{{$t('product.quantityBoth', {min : minQuantity, max : maxQuantity})}}&nbsp;</span>
					<span v-else-if="minQuantity">{{$t('product.quantityMin', {min : minQuantity})}}</span>
					<span v-else-if="maxQuantity">{{$t('product.quantityMax', {max : maxQuantity})}}</span>
				</span>
			</v-row>
		</template>
	</div>
</template>

<script>
	import { WindowWidthMixin } from '@/mixins/ProductMixin';
	export default {
		name: 'QuantityPicker',
		mixins : [WindowWidthMixin],
		props: {
			presentQuantity: {
				type: Number,
				default: 0
			},
			minQuantity: {
				type: Number | null,
				default: null
			},
			maxQuantity: {
				type: Number | null,
				default: null
			},
			incrementValue: {
				type: Number | null,
				default: 1
			},
			isModifier: {
				type: Boolean,
				default: false
			},
			isRequiredModifier: {
				type: Boolean,
				default: false
			},
			mainProductQuantity: {
				type: Number | null,
				required: false,
				default: 0
			},
			align: {
				type: String,
				default: 'left'
			},
		},
		data () {
			return {
				currentQuantity: this.presentQuantity,
			}
		},
		created() {
			this.$root.$on('addToCartSuccess', this.handleAddToCartSuccess);
		},
		beforeDestroy() {
			this.$root.$off('addToCartSuccess', this.handleAddToCartSuccess);
		},
		computed: {
			isModifierWithoutProduct() {
				return this.mainProductQuantity == 0 && this.isModifier;
			},
			modifierQtyLowerProductQty() {
				return this.isRequiredModifier && (this.currentQuantity - this.valueStep) < (this.minQuantity * this.mainProductQuantity);
			},
			canIncrement() {
				if (this.isModifierWithoutProduct) {
					return false;
				}

				return !this.maxQuantity || this.currentQuantity < this.maxQuantity;
			},
			canDecrement() {
				if (this.modifierQtyLowerProductQty) {
					return false;
				}

				return this.currentQuantity > 0;
			},
			valueStep() {
				return this.incrementValue !== null ? this.incrementValue : 1;
			}
		},
		watch: {
			mainProductQuantity () {
				this.setModifierQuantity();
			},
			presentQuantity() {
				this.currentQuantity = this.presentQuantity;

				if (this.isModifier && this.isRequiredModifier) {
					this.currentQuantity = this.minQuantity ? this.minQuantity * this.mainProductQuantity : this.mainProductQuantity;
				}

				this.$emit("quantityChanged", this.currentQuantity);

			}
		},
		methods: {
			setModifierQuantity() {
				if (this.mainProductQuantity == 0) {
					this.currentQuantity = 0;
				}

				if (this.mainProductQuantity > 0 && this.isRequiredModifier ) {
					this.currentQuantity = this.minQuantity ? this.minQuantity * this.mainProductQuantity : this.mainProductQuantity;
				}

				this.$emit("quantityChanged", this.currentQuantity);
			},
			increment() {
				if (this.canIncrement) {
					if ((this.minQuantity > 0) && this.currentQuantity == 0) {
						this.currentQuantity = this.minQuantity;
					} else {
						this.currentQuantity += this.valueStep;
					}
					this.$emit("quantityChanged", this.currentQuantity);
				}
			},
			decrement() {
				if (this.canDecrement) {
					if ((this.minQuantity > 0) && this.currentQuantity == this.minQuantity) {
						this.currentQuantity = 0;
					} else {
						this.currentQuantity -= this.valueStep;
					}
					this.$emit("quantityChanged", this.currentQuantity);
				}
			},
			handleAddToCartSuccess() {
				this.currentQuantity = 0;
			}
		}
	};
</script>

<style scoped lang="scss">
	.counter-value {
		font-weight: 600;
		font-size: 1.2rem;
	}
	.circular-button {
		border: 1px solid $grey-light;
		border-radius: 50%;
		width: 40px;
		height: 40px;
	}
	.counter-row {
		display: flex;
		align-items: center;
	}
</style>
