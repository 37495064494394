<template>
	<div>
		<v-dialog v-model="modalOpen" width="800" @click:outside="closeModal" content-class="cross-sell_modal">
			<div class="cross-sell_header">
				<h3 class="cross-sell_title">{{ $t('product.crossSells.title') }}</h3>
				<p class="text-subtitle-1 mt-3">{{ $t('product.crossSells.subTitle') }}</p>
			</div>

			<div class="text-center" v-if="interactiveMap">
				<v-card-text class="px-0 px-md-4">
					<h1 class="display-1" v-text="$t('interactiveMap.modalTitle')"></h1>
					<v-container class="cross-sell-container">
						<InteractiveMapButton :interactive-map="interactiveMap" :on-click="() => {_visible = false}" />
					</v-container>
				</v-card-text>

				<v-divider></v-divider>
			</div>

			<ProductCard
				v-for="(product, index) in crossSellProducts"
				class="mt-6"
				:key="index"
				:product="product"
				:products-selected="productsSelected"
				is-hard-cross-sell
				@quantityChanged="updateProductQuantity"
				@quantityModifierChanged="updateModifierQuantity"/>

			<div class="d-flex flex-wrap pa-4 cross-sell_footer" :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'">
				<v-btn class="cancel-button" outlined @click="goToCart" :block="$vuetify.breakpoint.xs">
					{{ $t('product.crossSells.backButton') }}
				</v-btn>
				<v-btn
					outlined
					class="add-to-cart-button"
					:disabled="!canAddToCart"
					:block="$vuetify.breakpoint.xs"
					@click="goNext">
					{{ nextLabel }}
				</v-btn>
			</div>
		</v-dialog>
		<ProductPackageSelector
			v-if="canAddToCart"
			ref="ProductPackageSelector"
			is-cross-sell
			:productsSelected="productsSelected"
			@addToCartSuccess="handleAddToCart"/>
	</div>
</template>

<script>
	import ProductCard from '@/components/ProductV2/ProductCard';
	import ProductV2Mixin from '@/mixins/ProductV2Mixin';
	import ProductPackageSelector from '@/components/ProductV2/ProductPackageSelector';
	import InteractiveMapButton from "@/components/InteractiveMap/InteractiveMapButton";

	export default {
		name: "CrossSellsModal",
		mixins: [ProductV2Mixin],
		components: {
			ProductCard,
			ProductPackageSelector,
			InteractiveMapButton
		},
		props: {
			isOpen: {
				type: Boolean,
				required: false,
			},
			crossSellProducts: {
				type: Array,
				default: () => ([]),
			},
		},
		data() {
			return {
				modalOpen: this.isOpen,
				productsSelected: new Map(),
				products: [],
				isCrossSell: true //Used in the ProductV2Mixin
			};
		},
		watch: {
			isOpen(newVal) {
				this.modalOpen = newVal;
			},
		},
		computed: {
			canAddToCart() {
				return this.productsSelected.size > 0
			},
			nextLabel() {
				if (this.canAddToCart && this.hasCalendar) {
					return this.$t('tag.chooseVisitingDate');
				}
				return this.$t('btn.addToCart');
			},
			interactiveMap() {
				return this.$root.websiteConfig.data.interactive_map;
			}
		},
		methods: {
			handleAddToCart() {
				this.productsSelected = new Map();
			},
			closeModal() {
				this.modalOpen = false;
				this.$emit("close");
			},
			goToCart() {
				this.$router.push({ name: "cart" });
			},
			goNext() {
				this.hasCalendar
					? this.$refs.ProductPackageSelector.openModal()
					: this.addToCart();
				this.closeModal();
			},
		},
	};
</script>

<style lang="scss" scoped>
	h3 {
		color: $blackish;
	}
	.text-subtitle-1 {
		color: $grey-dark;
	}
	.add-to-cart-button {
		border: 1px solid $blackish;
		background-color: $blackish;
		color: white;
	}
	.add-to-cart-button.v-btn--disabled {
		border: 1px solid $grey-light;
		background-color: $grey-light;
	}
	::v-deep {
		.v-dialog.v-dialog--active {
			border-radius: 15px;
			background-color:$grey-lighter !important;
			padding: 40px;
		}
	}
	.cross-sell{
		&_footer {
			row-gap: 16px;
			column-gap: 12px;
			position: sticky;
			bottom: -40px;
			background-color: $grey-lighter;
		}
	}


	@media screen and (max-width: 600px) {
		.cross-sell {
			&_modal{
				background: red;
			}
			&_header{
				padding:0 32px;
			}
			&_title {
				font-size: 21px;
				line-height: 28px;
			}
		}
		.text-subtitle-1 {
			font-size: 14px;
			line-height: 21px
		}
	}
</style>
<style lang="scss">
@media screen and (max-width: 600px) {
	.v-dialog.v-dialog--active.cross-sell_modal {
			padding: 32px 0;
			border-radius: 0;
		}
	}
</style>
