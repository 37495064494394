<template>
	<div>
		<v-container fluid>
			<v-row v-for="product in selectedProducts" :key="product.id" class="align-center mt-0">
				<v-col cols="12" class="d-flex justify-space-between grey-dark pb-0">
						<span class="name">{{ displayQuantity(product.quantity) }} {{ product.name }}</span>
					<div v-if="displayPrice" class="price">
						{{ $options.filters.currencyWithoutCountry(calculateProductTotal(product)) }}
					</div>
				</v-col>

				<v-col cols="12" v-if="product.modifiers.length" class="pt-0">
					<div v-for="modifier in product.modifiers" :key="modifier.name" class="ml-6 mt-3 d-flex justify-space-between grey-dark pb-0">
						<span>{{ displayQuantity(modifier.quantity) }} {{ modifier.name }}</span>
						<span class="price" v-if="displayPrice">{{ $options.filters.currencyWithoutCountry(calculateProductTotal(modifier)) }}</span>
					</div>
				</v-col>

				<v-col cols="12" v-if="product.combo_products.length" class="pt-0">
					<div v-for="combo in product.combo_products" :key="`${product.id}-${combo.id}`" class="ml-9 mt-3">
						{{ displayQuantity(combo.quantity * product.quantity) }} <span class="ml-3">{{ $options.filters.translatable(combo.name, combo.name_i18n, $i18n.locale) }}</span>
					</div>
				</v-col>
			</v-row>

			<hr class="mt-5 mb-5 divider">

			<v-row class="total" v-if="displayPrice && subTotal">
				<v-col>
					<div>{{ $t('product.subTotal') }} :</div>
				</v-col>
				<v-col class="text-right">
					<div>{{ $options.filters.currencyWithoutCountry(subTotal) }}</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import ProductV2Mixin from '@/mixins/ProductV2Mixin';
	export default {
		name: "ProductsSubTotalList",
		mixins: [ProductV2Mixin],
		props: {
			products: {
				type: Array,
				required : true,
			},
			selectedDatePrices: {
				type: Object ,
				required: false,
			},
			selectedTimeSlots: {
				type: Object ,
				required: false,
			},
			displayPrice: {
				type: Boolean ,
				default: false,
			}
		},
		data() {
			return {
				subTotal: 0,
				selectedProducts: []
			}
		},
		mounted(){
			this.addModifiersToProducts();
		},
		watch: {
			products() {
				this.addModifiersToProducts();
			},
			selectedTimeSlots: {
				handler: function(newValue) {
					this.udpatePrices();
				},
				deep: true
			}
		},
		methods: {
			addModifiersToProducts() {
				this.selectedProducts = [];

				this.products.forEach((product) => {

					const modifiers = [];

					if (product.modifiers.size > 0) {
						product.modifiers.forEach((item) => {

							modifiers.push({
								id: item.modifier.id,
								quantity: item.quantity,
								name: item.modifier.name,
								price: item.modifier.price,
								required: item.modifier.required,
								event_group_id: item.modifier.event_group_id,
								price_lowest_available: item.modifier.price_lowest_available,
								price_show_lowest: item.modifier.price_show_lowest
							})
						});
					}
					product.modifiers = modifiers;
					this.selectedProducts.push(product);
				})
			},
			udpatePrices() {
				this.subTotal = 0;

				this.selectedProducts.forEach(product => {
					this.getPrice(product, false);

					product.modifiers.forEach(modifier => {
						this.getPrice(modifier, true);
					});
				});
			},
			getPrice(product, isModifier) {
				if (product.event_group_id) {
					const timeSlot = this.getSlotProductSelected(product.id);

					if (timeSlot) {
						product.selected_price = timeSlot.price * product.quantity;
						this.updateSubTotal(product.selected_price);
					} else {
						this.updateSubTotal(product.price_lowest_available.lowest_available * product.quantity);
					}
				} else {
					product.selected_price = isModifier ? product.price * product.quantity : product.price;
					this.updateSubTotal(product.selected_price);
				}
			},
			updateSubTotal(price) {
				this.subTotal += price;
			},
			findTimeSlot(productId) {
				return Object.values(this.selectedTimeSlots).find(el => el.product_id == productId);
			},
			calculateProductTotal(product) {
				if (product.selected_price) {
					return product.selected_price.toFixed(2);
				} else {
					return product.price_lowest_available.lowest_available.toFixed(2) * product.quantity;
				}
			},
			displayQuantity(quantity) {
				return `${quantity}x`;
			},
		}
	};
</script>

<style lang="scss" scoped>
	.grey-light {
		color: $grey-light;
	}
	.grey-dark {
		color: $grey-dark;
	}
	.price {
		font-weight: bold;
		color: $grey-dark;
	}
	.divider {
		border-top: 1px solid $grey-light;
	}
	.total {
		font-size: 1.3rem;
		font-weight: 600;
	}
</style>
